export const apiURL = 'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com';
export const apiKEY = 'aviel_S38M2jgt423n2tUX9qSHDj7nnX';

// Créez une instance Axios avec des configurations de base (par exemple, l'URL de base)
/**
 * Description
 * @param {String} endpoint
 * @param {String} otherQueryParams | Ajouter d'autre paramètres '&display=full'
 * @param {Object} requestData={}
 * @returns {JSON}
 */
const api = async (endpoint, otherQueryParams = '', requestData = {}) => {
  try {
    const response = await fetch(`${apiURL}/api/${endpoint}?ws_key=${apiKEY}&output_format=JSON&${otherQueryParams}`, requestData);

    return await response.json();
  } catch (error) {
    console.log('Erreur lors de la requête:', error);
    throw error; // Vous pouvez choisir de relancer l'erreur ici ou la gérer d'une autre manière
  }
};

// Ajoutez d'autres fonctions pour d'autres types de requêtes (PUT, DELETE, etc.) au besoin

// Exportez l'instance Axios pour une utilisation directe si nécessaire
export default api;
