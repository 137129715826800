import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOneProduct, useProduct, useProductCategorie } from '../hooks/usePrestashopProducts';
import HeaderComponent from '../components/headerComponent';
import LoadingComponent from '../components/loadingComponent';
import SliderProductComponent from '../components/sliderProductsComponent';
import ModalExtensionGarantieComponent from '../components/modalExtensionGarantieComponent';
import { useCartState } from '../store/store';

function ProductPage() {
  const { linkrewrite } = useParams();
  const {product, loading, error} = useProduct(linkrewrite);
  const { addToCart } = useCartState();
  const [selectedImage, setSelectedImage] = useState();
  const [optionGarantie, setOptionGarantie] = useState([{'duree': '5 ans', 'price': 350, 'index': 0}]);
  const [optionDeballage, setOptionDeballage] = useState(false);
  const [optionSerenite, setOptionSerenite] = useState(false);
  const [tab, setTab] = useState(0);
  // const {productByCat, errorProductCat, loadingProductCat} = useProductCategorie(product.id_category_default, 'limit=[4]');

  // Modals
  const [modalExtension, setModalExtension] = useState(false);
  const [modalFinancement, setModalFinancement] = useState(false);
  const [modalRecyclage, setModalRecyclage] = useState(false);

  useEffect(() => {
    console.log(product)
    setSelectedImage(product.image_defaut);
  }, []);


  const chooseGarantie = (optionIndex) => {
    // Logique spécifique pour choisir une garantie
    setOptionGarantie(optionIndex)
    setModalExtension(false)
  };


  const addToCartProduct = () => {
    addToCart({
      'product_id': product.id,
      'quantity': 56,
      'options': {
      }
    })
  }



  
  return (
    <>
      <HeaderComponent />

      {modalExtension && (
        <ModalExtensionGarantieComponent onChooseOption={chooseGarantie} initialValue={optionGarantie} />
      )}

      {
        loading ? <LoadingComponent />
        :
        <div className="container mx-auto">

          {/* Fil d'ariane */}
          <div className="py-6">
            <span>Tous les produits / {product.categorie_name_defaut} / {product.name}</span>
          </div>

          
          <div className='md:flex md:flex-row py-6'>
            <div className='md:w-2/6 md:flex md:flex-row'>
              
              <div className={`flex md:flex-col items-center md:w-1/6`}>
              {
                product.images && product.images.length > 1
                  ? product.images.map((element, index) => (
                      <div key={element.id} className={selectedImage == element ? 'border border-orange': ''}>
                        <img
                          src={element}
                          alt={'image-'+product.name+'-'+index}
                          onClick={() => setSelectedImage(element)}
                        />
                      </div>
                    ))
                  : null
              }
              </div>
              <div className='relative'>
                <div className='absolute top-0 right-0 z-10'>{
                  <span className='bg-[#FA000033] text-[#D90E0E] text-xs rounded-full font-semibold p-2'>{
                  product.specific_prices && product.specific_prices.map((price) => (
                    price.reduction_type == 'percentage' ? '-' + parseFloat(price.reduction * 100).toFixed(2) + ' %': '- ' + parseFloat(price.reduction).toFixed(2) + ' €'
                  ))
                  }</span>

                }
                </div>
                <div>
                  <img className='w-full' src={selectedImage} alt={'produit-' + product.link_rewrite} />
                </div>
                <div className='flex flex-row'>
                  <img src={window.location.origin + '/images/service-clients 1.png'} alt="" />
                  <div className='pl-2'>
                    <p>Besoin d’aide ? Un conseiller vous répond et vous guide : <strong><a href="tel:0 805 130 013">0 805 130 013</a></strong></p>
                    <p className='text-xs'>Coût d’un appel local depuis la France</p>
                  </div>
                </div>
              </div>
              
            </div>
            <div className='px-6 lg:w-4/6'>
              <h1 className='text-3xl font-semibold'>{product.name}</h1>
              <div className='flex items-center justify-between'>
                <div className='text-grey-Accent'>
                  <span className='text-greyAccent italic'>Réf: {product.reference}</span>
                  <span className='pl-6'>État: <span className='font-semibold'>{product.condition == 'new' ? 'Neuf' : 'Occasion'}</span></span>
                </div>
                <span className='inline-flex items-center bg-grey p-2 rounded-xl text-xs'>
                  <img src={window.location.origin +'/images/delivery-24h.png'} alt="" />
                  <span className='pl-3 font-bold text-black'> Livré en 24h</span>
                </span>
              </div>
              <div className='py-4' dangerouslySetInnerHTML={ {__html: product.description_short} } />

              <div className="md:flex md:flex-row">
                <div className='md:w-2/4 flex flex-col justify-between'>
                  <div>
                    <div>
                      <span className='inline-flex items-center bg-greenBg p-2 rounded-xl text-xs'>
                        <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                          <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                        </svg>
                        <span className='pl-3 font-semibold text-greenAccent'> En stock</span>
                      </span>
                    </div>
                    <div className='py-2'>
                      <span className='inline-flex items-center bg-grey p-2 rounded-xl text-xs'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                        <span className='pl-3 font-bold text-black'> Livraison gratuite</span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className='text-[#A9A9A9] line-through text-xl'>1090,00 € HT</div>
                    <div className='text-orange text-4xl font-semibold'>{parseFloat(product.wholesale_price).toFixed(2)} € <span className='text-xs text-greyAccent'>HT</span></div>
                    <div className='text-greyAccent text-xl'>{parseFloat(product.price).toFixed(2)} TTC</div>
                  </div>
                  <div>
                    <div className='text-black text-3xl font-light'> TOTAL :  <span className='font-semibold'>{parseFloat(product.price).toFixed(2)}</span> <span className='text-sm '>HT</span></div>
                  </div>
                  <div>
                    <button className='inline-flex items-center text-xs font-bold rounded-xl border-orange border p-3'>
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.47339 5.66661C2.00667 4.38607 2.96718 3.32986 4.19147 2.67771C5.41576 2.02556 6.82819 1.81777 8.18843 2.08968C9.54867 2.3616 10.7727 3.09643 11.6522 4.16913C12.5316 5.24183 13.0123 6.58613 13.0123 7.97328C13.0123 9.36043 12.5316 10.7047 11.6522 11.7774C10.7727 12.8501 9.54867 13.585 8.18843 13.8569C6.82819 14.1288 5.41576 13.921 4.19147 13.2688C2.96718 12.6167 2.00667 11.5605 1.47339 10.2799" stroke="#E6704B" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 3.33325V5.86659C1 5.90195 1.01405 5.93586 1.03905 5.96087C1.06406 5.98587 1.09797 5.99992 1.13333 5.99992H3.66667" stroke="#E6704B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className='pl-2'>Recycler mon ancien matériel {product.manufacturer_name}</span>
                    </button>
                  </div>
                </div>
                <div className='md:w-2/4'>
                  <div className=' bg-grey rounded-xl p-3'>
                    <h3 className='font-semibold text-2xl'>Protégez votre investissement !</h3>
                    <p>Profitez de votre  traceur sans prise de tête</p>
                    <div className={`border-2 border-black rounded-xl p-4 my-2 ${optionGarantie.duree != '0 ans' ? 'bg-orange text-white' : 'bg-white text-black' }`} onClick={() => setModalExtension(true)}>
                      <p className="text-xl font-bold">Extension de garantie <span className='text-black'>{optionGarantie.duree != '0 ans' ? optionGarantie.duree : ''}</span></p>
                      <p className="font-light text-xs">
                      Profitez d’une installation de votre matériel en toute sérénité.
                      Votre matériel est garantie pendant 5 ans pièce et mains d’oeuvre
                      </p>
                    </div>
                    <div className={`border-2 border-black rounded-xl my-2 p-4 ${optionDeballage ? 'bg-orange text-white' : 'bg-white text-black' }`} onClick={() => setOptionDeballage(!optionDeballage)}>
                      <p className="text-xl font-bold">Déballage, installation et formation</p>
                      <p className="font-light text-xs">
                        Nos techniciens vous livrent, installent et vous forment sur l’utilisation du matériel 
                      </p>
                    </div>
                    <div className={`border-2 border-black rounded-xl my-2 p-4 ${optionSerenite ? 'bg-orange text-white' : 'bg-white text-black' }`} onClick={() => setOptionSerenite(!optionSerenite)}>
                      <p className="text-xl font-bold">Option sérénité -1%</p>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 py-6">
                      <div className="w-1/3 border-2 rounded-xl border-black bg-grey p-3">
                        1 PCS
                      </div>
                      <div onClick={addToCartProduct} className="w-2/3 bg-green text-white text-center font-bold rounded-xl flex  items-center justify-center">
                        Ajouter au panier
                      </div>
                  </div>
                  <div className="flex flex-row gap-2 my-2">
                    <div className="w-1/3">
                    </div>
                    <div className="w-2/3 bg-grey border-2 border-black p-3 text-xs text-black text-center font-bold rounded-xl flex  items-center justify-center">
                        Simuler un financement 
                      </div>
                  </div>
                </div>

              </div>
              <div className="bg-red rounded-md p-2 text-white mt-8 text-sm flex flex-row justify-between items-center">
                <div className='flex flex-row w-4/5'>
                  <img className='pr-2' src={window.location.origin + '/images/Warning.png'} alt="" /> Ce produit nécessite l’achat de produits complémentaires.  
                </div>
                <button className='bg-white rounded-lg font-bold text-black text-xs p-3'>Voir les produits</button>
              </div>
              
            </div>
          </div>
         <div className='py-24'>
            <div className='md:grid md:grid-cols-5 md:gap-5'>
                {
                  product.description ? <div><h4 className='font-semibold' onClick={() => setTab(0)}>Description</h4> </div> : null
                }
                {
                  product.features ?  <div><h4 className='font-semibold' onClick={() => setTab(1)}>Caractéristiques</h4></div> : null
                }
                {
                 product.pdf && product.pdf.length > 0 ?  <div><h4 className='font-semibold' onClick={() => setTab(2)}>Documentation <span className='rounded-full text-orange bg-[#F4F8EC] px-4 ml-2'>{product.pdf.length}</span> </h4></div> : null
                }
              <div>
                <h4 className='font-semibold' onClick={() => setTab(3)}>Consommables</h4>
              </div>
              <div>
                <h4 className='font-semibold' onClick={() => setTab(4)}>Accessoires</h4>
              </div>
            </div>
            <div className='md:grid md:grid-cols-5 py-4'>
              {
                product.description ? <div className={`h-0.5 ${tab == 0 ? 'bg-orange' : 'bg-grey'}`}></div> : null
              }
              {
                product.features ? <div className={`h-0.5 ${tab == 1 ? 'bg-orange' : 'bg-grey'}`}></div> : null
              }
              {
                product.pdf && product.pdf.length > 0 ? <div className={`h-0.5 ${tab == 2 ? 'bg-orange' : 'bg-grey'}`}></div> : null
              }
              
              <div className={`h-0.5 ${tab == 3 ? 'bg-orange' : 'bg-grey'}`}></div>
              <div className={`h-0.5 ${tab == 4 ? 'bg-orange' : 'bg-grey'}`}></div>
            </div>
            <div className='py-6'>
              {tab === 0 && product.description ? <div className='py-4' dangerouslySetInnerHTML={ {__html: product.description} } /> : null}
              {tab === 1 && product.features ? 
                product.features.map((feature, index) => (  
                  <div key={index} className={`grid grid-cols-2 gap-1 text-black font-medium py-2  ${index % 2 == 0 ? 'bg-[#F5F5F5]' : 'bg-white'} `} >
                    <span>{feature[0]}</span>
                    <span>{feature[1]}</span>
                  </div>
                ))
                : null
              }
              <div className='grid grid-cols-2 gap-1 justify-center text-black font-medium'>
              {tab === 2 && product.pdf.length > 0 ?
                  product.pdf.map((element, index) => (  
                    <div key={index} className={` text-black font-medium py-2 `} >
                      <p className='font-medium py-2'>{element.file_name}</p>
                      <a href={element.file_url} className='border rounded-lg text-xs px-4 py-2 border-greyAccent'>Télécharger le document ({element.size}M)</a>
                    </div>
                  ))
                : null
              }
              </div>
              {tab == 3 ? <div className='py-4' dangerouslySetInnerHTML={ {__html: product.description} } /> : null}
              {tab == 4 ? <div className='py-4' dangerouslySetInnerHTML={ {__html: product.description} } /> : null}
            </div>
         </div>
        </div>
        
      }
      <div className='bg-grey'>
        <div className="container mx-auto py-12">
          <div className='text-center md:text-left font-semibold text-2xl'><h2>Produits dans la même catégorie : </h2></div>
          <div className="py-6">
                {/* {
                    loading ? <LoadingComponent /> : <SliderProductComponent products={productByCat} />
                } */}
            </div>
        </div>
      </div>
      <div className="container mx-auto">
          <div className="lg:grid lg:grid-cols-4 lg:gap-4 lg:py-6 py-12 flex items-end">
            <div className='flex flex-col items-center'>
              <img src={window.location.origin + '/images/Truck.png'} alt="" />
              <p className="font-bold">Livraison Express</p>
              <p className="text-xs">Retrait ou 24 dans toute l’Europe</p>
            </div>
            <div className='flex flex-col items-center'>
              <img src={window.location.origin + '/images/Credit card.png'} alt="" />
              <p className="font-bold">Paiement sécurisé</p>
              <p className="text-xs">Cb, Paypal, Chèque, Mandat administratif</p>
            </div>
            <div className='flex flex-col items-center'>
              <img src={window.location.origin + '/images/Reuse.png'} alt="" />
              <p className="font-bold">Politique de retours</p>
              <p className="text-xs">Retrait ou 24 dans toute l’Europe</p>
            </div>
            <div className='flex flex-col items-center'>
              <img src={window.location.origin + '/images/Tools.png'} alt="" />
              <p className="font-bold">Maintenance et réparation</p>
              <p className="text-xs">Retrait ou 24 dans toute l’Europe</p>
            </div>
          </div>
      </div>
    </>
  )
}

export default ProductPage