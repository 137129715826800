import React, { useEffect, useState } from 'react'
import '../components/headerComponent'
import HeaderComponent from '../components/headerComponent';
import { Carousel } from 'react-responsive-carousel';
import api, { fetchData } from '../utils/fetch';
import ProductCardComponent from '../components/productCardComponent';
import { Link } from 'react-router-dom';
import {useProductCategorie} from '../hooks/usePrestashopProducts';
import SliderProductComponent from '../components/sliderProductsComponent';
import LoadingComponent from '../components/loadingComponent';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function HomePage() {

    const { productByCat, error, loading } = useProductCategorie('879');
    const configSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      };
    
  return (
    <>
        <HeaderComponent />

        <div className='container mx-auto'>
            <div className="py-6">
                <Slider {...configSlider} >
                    <div>
                        <div className='h-96 w-full p-12 bg-greyAccent rounded-lg'>
                            <p className='text-orange font-extrabold text-xl'>OFFRE SPÉCIALE</p>
                            <p className='py-3 font-semibold text-3xl text-white'>Jusqu’à 100 € remboursés</p>
                            <p className='py-3 font-light text-3xl text-white'>Sur la gamme HP DesignJet 730</p>
                            <div className='pt-6'>
                                <button className='px-6 py-2 bg-orange rounded-lg text-center text-white'>Découvrir les Traceurs HP</button>
                            </div>
                        </div>
                    </div>
                    
                </Slider>
            </div>
            <div className='text-center py-6'>
                <h1 className='font-bold text-xl md:text-2xl py-2'>La Boutique du Traceur,</h1>
                <h2 className='text-xl md:text-2xl'>Partenaires agréé des plus grandes marques HP, Canon, Epson.</h2>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 border-grey border rounded-lg md:py-6'>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-garantie.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Garantie</p>
                        <p>jusqu’à 5 ans</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-leasing.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Solution de leasing</p>
                        <p>6 à 64 mois</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-delivery.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Livraison en Europe</p>
                        <p>Multi-transporteur</p>
                    </div>
                </div>
                <div className='flex items-center md:justify-center py-3 px-6 md:py-0 md:px-0'>
                    <div><img src="/images/icon-promo.png" alt="" /></div>
                    <div className='pl-3 text-left'>
                        <p className='font-bold text-xl'>Prix attractifs</p>
                        <p>leader du marché</p>
                    </div>
                </div>
                
            </div>
            <div className='grid md:gap-2 md:grid-cols-2 py-12'>
                <div className='text-center md:text-left font-semibold text-2xl'><h3>Meilleurs ventes</h3></div>
                <div className='text-right py-2 md:py-0'>Tous les produits</div>
            </div>
            <div className="pb-6">
                {
                    loading ? <LoadingComponent /> : <SliderProductComponent products={productByCat} />
                }
            </div>

        </div>
        <div className='bg-grey'>
            <div className='container mx-auto'>
                <div className="py-4 md:py-24 md:flex md:flex-row">
                    <div style={{'--image-url-categorie-cartouches': `url(images/categorie-cartouches.png)`}} className="md:w-2/6 h-64 md:h-[37rem] rounded-lg bg-cover bg-center bg-[image:var(--image-url-categorie-cartouches)] flex flex-col justify-end p-4">
                        <h3 className='font-semibold text-white'>Cartouches d’encre</h3>
                        <p className='font-medium text-white'>Voir les produits</p>
                    </div>
                    <div className="md:w-4/6">
                        <div style={{'--image-url-categorie-rouleau-papier': `url(images/categorie-rouleau-papier.png)`}}  className="mt-4 md:mt-0 md:ml-4 rounded-lg h-32 md:h-72 bg-cover bg-center bg-[image:var(--image-url-categorie-rouleau-papier)] flex flex-col items-end justify-end p-4">
                            <h3 className='font-semibold'>Têtes d’impression</h3>
                            <p className='font-medium'>Voir les produits</p>
                        </div>
                        <div className="flex flex-row pt-4 md:pl-4">
                            <div style={{'--image-url-categorie-tete-impression': `url(images/categorie-tete-impression.png)`}}  className="w-1/2 h-32 md:h-72 bg-cover bg-center bg-[image:var(--image-url-categorie-tete-impression)] flex flex-col justify-end p-4">
                                <h3 className='font-semibold'>Têtes d’impression</h3>
                                <p className='font-medium'>Voir les produits</p>
                            </div>
                            <div style={{'--image-url-accessoire': `url(images/categorie-accessoire.png)`}}  className="w-1/2 ml-4 h-32 md:h-72 bg-cover bg-center rounded-lg bg-[image:var(--image-url-accessoire)] flex flex-col justify-end p-4">
                                <h3 className='font-semibold'>Accessoires</h3>
                                <p className='font-medium'>Voir les produits</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container mx-auto'>
            <div className='grid md:gap-2 md:grid-cols-2 py-12'>
                <div className='text-center md:text-left font-semibold text-2xl'><h3>Produits en promotion</h3></div>
                <div className='text-right py-2 md:py-0'>Tous les produits</div>
            </div>
            <div className="pb-6">
                {
                    loading ? <LoadingComponent /> : <SliderProductComponent products={productByCat} />
                }
            </div>
        </div>
    </>
  )
}

export default HomePage