import React from 'react'
import { Link } from 'react-router-dom'

function ProductCardComponent({product}) {
  return (
    <>
      <div className='px-2'>
        <div className="border-greySoft border rounded-lg p-4 bg-white">
          <img src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/products/'+product.id+'/'+product.id_default_image+'?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX&output_format=json'} alt={'produit-' + product.link_rewrite} />
          <h4 className='text-black font-medium text-md'>{product.name}</h4>
          <p className='text-greyAccent py-3 text-sm'>Ref : {product.reference}</p>
          <p className='font-semibold text-2xl'>{parseFloat(product.wholesale_price).toFixed(2)} € <span className='text-xs'>HT</span></p>
          <p className='text-greyAccent text-sm'>{parseFloat(product.price).toFixed(2)} TTC</p>
          <div className='py-4'>
            <div className='text-green font-semibold items-center flex flex-row justify-end'> <div className='bg-green h-4 w-4 rounded-full mr-2'></div> En stock</div>
            <div className='text-green font-semibold items-center flex flex-row justify-end'> <div className='bg-green h-4 w-4 rounded-full mr-2'></div> 3 pièces restantes</div>
            <div className='text-orange font-semibold items-center flex flex-row justify-end'> <div className='bg-orange h-4 w-4 rounded-full mr-2'></div> Fin de stock</div>
          </div>
          <div>
            <button className='w-full border-grayAccent border-2 border-greyAccent rounded-xl p-2 font-bold text-greyAccent'><Link to={window.location.origin + '/' +product.category_link_rewrite+'/'+product.id+'-'+product.link_rewrite}>Voir le produit </Link></button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCardComponent