import React, { useEffect, useState } from "react";
import api, { apiKEY, apiURL } from "../utils/fetch";


/**
 * Recupère les produits de la categorie avec l'id de catégorie
 * @param {any} id
 * @returns {any}
 */
const useProductCategorie = (id, limit = '') => {

  const [productByCat, setProductByCat] = useState([])
  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)

  useEffect(() => {
      (
          async function(){
              try{
                  setLoading(true)
                  const categorieData = await api(`categories/${id}`, `limit=[8]`);
      
                  // Use map to create an array of promises
                  const productPromises = categorieData.category.associations.products.map(async (element) => {
                    const product = await api(`products/${element.id}`, 'filter[state]=1');
                    const catName = await api(`categories/${product.product.id_category_default}`);
                    product.product['category_default_name'] = catName.category.name;
                    product.product['category_link_rewrite'] = catName.category.link_rewrite;
                    console.log(product.product)
                    return product.product;
                  });
                
                  // Update the state with the fetched products
                  setProductByCat(await Promise.all(productPromises));
              }catch(err){
                  setError(err)
              }finally{
                  setLoading(false)
              }
          }
      )()
  }, [id])

  return { productByCat, error, loading }

}



/**
 * Récupère un produit par son link_rewrite
 * @param {any} id=''
 * @param {any} linkrewrite=''
 * @returns {any}
 */
const useProduct = (LINKREWRITE = '') => {

  const [product, setProduct] = useState([])
  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)

  useEffect(() => {
      (
          async function(){
              try{
                  setLoading(true)

                    // Récupération du produit
                    const productData = await api(`products/${LINKREWRITE}`);

                    // Ajout des images au produit
                    let productImages = [];
                    productData.product.associations.images.forEach( element => {
                        productImages.push(`${apiURL}/api/images/products/${productData.product.id}/${element.id}?ws_key=${apiKEY}`)
                    });
                    productData.product['images'] = productImages;


                    // Ajout de l'image par défaut
                    productData.product['image_defaut'] = `${apiURL}/api/images/products/${productData.product.id}/${productData.product.id_default_image}?ws_key=${apiKEY}`;


                    // Ajout des prix spécifiques
                    const productPrice = await api(`specific_prices`, `display=full&filter[id_product]=${productData.product.id}`);
                    productData.product['specific_prices'] = productPrice.specific_prices;

                    // Ajout de la categorie    
                    const catName = await api(`categories/${productData.product.id_category_default}`);
                    productData.product['categorie_name_defaut'] = catName.category.name;
                    
                    // Ajout des caracteristiques produit
                    let productFeatures = [];
                    productData.product.associations.product_features.forEach(async element => {
                        const productFeature = await api(`product_features/${element.id}`)
                        const productFeatureValues = await api(`product_feature_values/${element.id_feature_value}`)
                        
                        productFeatures.push([productFeature.product_feature.name, productFeatureValues.product_feature_value.value]);
                    });
                    productData.product['features'] = productFeatures;

                    // Ajout des documentations techniques
                    let productAttachments = [];
                    productData.product.associations.attachments.forEach( async element => {
                        const productAttachement = await api(`attachments/${element.id}`);
                        console.log(productAttachement)
                        productAttachments.push({
                            'file_name': productAttachement.attachment.name,
                            'file_url': `${apiURL}/api/attachments/file/${element.id}?ws_key=${apiKEY}`,
                            'size': (productAttachement.attachment.file_size / (1024 * 1024)).toFixed(2)
                        });
                    });
                    productData.product['pdf'] = productAttachments;

                    
                    setProduct(productData.product);
                  
              }catch(err){
                  setError(err)
              }finally{
                  setLoading(false)
              }
          }
      )()
  }, [LINKREWRITE])

  return { product, error, loading }

}


export {useProductCategorie, useProduct};