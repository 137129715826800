import { hookstate, useHookstate } from "@hookstate/core";

const initialState = hookstate({
  count: 201,
  isgood: false,
  isAuth: false
});

export const useGlobalState = () => {
  const state = useHookstate(initialState);

  return {
    getCount: () => state.count.value,
    increment: () => {
      state.count.set((count) => count + 1);
    },
    decrement: () => {
      state.count.set((count) => count - 1);

      state.isgood.set((isgood) => true);
    },
  };
};


// STORE Panier (cart)

const initialCartState = hookstate({
  cart: JSON.parse(localStorage.getItem('cart')) || []
});

export const useCartState = () => {
  const state = useHookstate(initialCartState);

  return {
    getCart: () => state.cart.value,
    addToCart: (item) => {
      state.cart.set((cart) => {
        const updatedCart = [...cart, item];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      });
    },
    removeFromCart: (itemId) => {
      state.cart.set((cart) => cart.filter((item) => item.id !== itemId));
    }
  };
};