import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function HeaderComponent() {
    const [categories, setCategories] = useState(['Traceurs', 'SCANNERS', 'PLIEUSE/COUPEUSES', 'CARTOUCHE D’ENCRE', 'TÊTES D’IMPRESSION', 'PAPIERS', 'ACCESSOIRES']);

    

  return (
    <>
    {/* Menu Desktop */}
    <div className='hidden md:block'>
        <div className='container mx-auto'>
            <div className="py-4 border-b border-grey">
                <div className="flex flex-wrap justify-between">
                    <div className='w-1/3'>
                        Besoin d’aide ? Appelez-nous au <a className='text-orange font-semibold' href="tel:01 48 58 26 26">01 48 58 26 26</a>
                    </div>
                    <div className='font-bold w-1/3'>Livraison gratuite dès 300€HT d'achat</div>
                    <div className='flex flex-wrap justify-around w-1/3'>
                        <div><a href="">Blog</a></div>
                        <div><a href="">A propos</a></div>
                        <div><a href="">Faq</a></div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-3">
                <div className='w-1/4 flex justify-center'><Link to="/"><img className='w-full' src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></Link></div>
                <div className=''>
                    <div className='flex flex-row bg-grey border-greyAccent border px-3 py-2 rounded-lg'>
                        <div className='text-black font-bold text-xl flex items-center pr-3'>
                            Catégories <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-3 w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                        </div>
                        <input className='w-full bg-grey visible' type="text" placeholder='Rechercher une référence, produit... ' />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.0} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>

                    </div>
                    <div className="flex flex-row py-2 justify-between">
                        <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                        Réparer mon traceur
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                        <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                            Calculer une reprise
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                        <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                            Vérifier ma garantie
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='w-1/4 flex justify-end'>
                    <div className='flex'>
                        <div className='text-left'><p className='text-xs'>Bonjour, identifiez-vous</p><p className='font-semibold'>Mon compte</p></div>
                        <img src="/images/icon-user.png" alt="" />
                    </div>
                <div>
                        <div className='text-left'><p className='text-xs'>690,00 €</p><p className='font-semibold'><Link to={'/panier'}>Mon panier</Link></p></div>
                </div>
                </div>
            </div>
        </div>
        <div className="bg-orange">
            <div className="container mx-auto flex py-3 justify-between">
            <a href="ee" className='uppercase text-white'>TRACEUR</a>
            <a href="ee" className='uppercase text-white'>Scanners</a>
            <a href="ee" className='uppercase text-white'>Plieuses/coupleuses</a>
            <a href="ee" className='uppercase text-white'>Plieuses/coupleuses</a>
            <a href="ee" className='uppercase text-white'>Plieuses/coupleuses</a>
            <a href="ee" className='uppercase text-white'>Plieuses/coupleuses</a>
            <a href="ee" className='uppercase text-white'>Plieuses/coupleuses</a>
                {
                    categories.map((cat,index) => {
                        <a key={index} href="ee" className='uppercase text-white'>{cat}</a>
                    })
                }
            </div>
        </div>
    </div>
    {/* Menu Mobile */}
    <div className='block md:hidden'>
        <div className="container mx-auto">
            <div className="flex flex-row items-center">
                <div className='w-1/5'><img src="/images/menu-icon-mobile.svg" alt="" /></div>
                <div className='w-3/5'><img src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></div>
                <div className='w-1/5 flex flex-row justify-around'>
                    <div><img src="/images/icon-user.png" alt="" /></div>
                    <div><img src="/images/icon-panier.svg" alt="" /></div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default HeaderComponent