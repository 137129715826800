import React, { useState } from 'react'
import { useCartState } from '../store/store';

export const PanierPage = () => {
  const { getCart } = useCartState();
  const [cart, setCart] = useState(getCart());

  

  return (
    <div> Panier State {JSON.stringify(cart)} 
    </div>
  )
}
