import React from 'react'

const LoadingComponent = () => {
  return (
    <div class="flex items-center justify-center h-screen p-5 bg-grey min-w-screen">

        <div class="flex space-x-2 animate-pulse">
            <div class="w-3 h-3 bg-orange rounded-full"></div>
            <div class="w-3 h-3 bg-orange rounded-full"></div>
            <div class="w-3 h-3 bg-orange rounded-full"></div>
        </div>

    </div>

  )
}

export default LoadingComponent