import React from 'react'
import HeaderComponent from '../components/headerComponent'

const LoginPage = () => {
  return (
    <>
        <HeaderComponent />
        <div className='md:'>
            <div className='bg-greySoft flex flex-col items-center rounded-lg shadow-lg'>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Nom" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Prénom" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="E-mail" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Téléphone" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Date de naissance" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Mot de passe" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Nom de l'entreprise" id="" /></div>
                <div className='py-2 w-1/2'><input className='w-full p-4 rounded-lg border border-greyAccent' type="text" placeholder="Domaine d'activité" id="" /></div>
            <div className='w-1/2'>
                <input type="checkbox" name="" id="" />
                <p className='text-greyAccent'>Les données collectées sont nécessaires pour permettre la gestion et le suivi de vos commandes. En créant votre compte vous acceptez notre politique de protection des données.</p>
            </div>
            </div>
        </div>
    </>
  )
}

export default LoginPage