import React, { useEffect, useState } from 'react'

const ModalExtensionGarantieComponent = ({onChooseOption, initialValue}) => {

    console.log(initialValue)


    const [option, setOption] = useState(0);


    useEffect(() => {
        // Perform any logic you need when the initialValue changes
        // For example, you might want to update the state based on the initialValue prop
        setOption(initialValue);
      }, [initialValue]);
    

    const chooseOptionFromParent = () => {
        switch (option) {
            case 0:
                onChooseOption({'duree': '5 ans', 'price': 350, 'index': 0})
                break;
            case 1:
                onChooseOption({'duree': '4 ans', 'price': 300, 'index': 1})
                break;
            case 2:
                onChooseOption({'duree': '3 ans', 'price': 250, 'index': 2})
                break;
                // Aucune garantie selectionnée
            case 3:
                onChooseOption({'duree': '0 ans', 'price': 0, 'index': 3})
                break;
        
            default:
                onChooseOption({'duree': '5 ans', 'price': 350, 'index': 0})
                break;
        }
    }



  return (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="bg-white py-12 px-12 my-8 rounded-xl shadow-lg z-50 xl:max-w-screen-2xl md:max-w-screen-md sm:max-w-screen-sm">
            <div className="">
                <div className='flex flex-row justify-between'>
                <div className=''>
                    <h1 className='md:text-4xl font-semibold'>Protégez votre investissement !</h1>
                    <p className='mt-4 md:w-3/4'>Choisissez nos extensions de garantie et préservez votre traceur grand format en toute sérénité !</p>
                </div>
                <div className=''>
                    <img src={window.location.origin + '/images/Warranty.png'} alt="" />
                </div>
                </div>
                <p className='py-8 font-light'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </p>
                <div className="w-full h-0.5 bg-greyAccent"></div>
                <div className="">
                    <h2 className='text-center py-6 text-black font-semibold md:text-2xl'>Sélectionnez une extension de garantie : </h2>
                    <div className="md:flex md:flex-row justify-center gap-6">
                        <div className={`border rounded-xl p-4 my-3 md:my-0 ${option === 0 ? 'bg-orange text-white': 'bg-white text-black'} ` }>
                            <p className="text-4xl font-bold text-center">5 ans</p>
                            <p className="text-center">Le + sur</p>
                            <p className="text-4xl font-bold text-center py-8">350,00 €</p>
                            <ul class='list-outside list-disc ml-6'>
                            <ul className=' list-disc'>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                            </ul>
                            </ul>
                            <div className="pt-8 text-center">
                                <button className="rounded-full border bg-white text-black border-greySoft uppercase font-bold px-4 py-2" onClick={() => setOption(0)}>Choisir</button>
                            </div>
                        </div>
                        <div className={`border rounded-xl p-4 my-3 md:my-0 ${option === 1 ? 'bg-orange text-white': 'bg-white text-black'} ` }>
                            <p className="text-4xl font-bold text-center">4 ans</p>
                            <p className="text-center">Meilleure vente</p>
                            <p className="text-4xl font-bold text-center py-8">300,00 €</p>
                            <ul>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                            </ul>
                            <div className="pt-8 text-center">
                                <button className="rounded-full border bg-white text-black border-greySoft uppercase font-bold px-4 py-2" onClick={() => setOption(1)}>Choisir</button>
                            </div>
                        </div>
                        <div className={`border rounded-xl p-4 my-3 md:my-0 ${option === 2 ? 'bg-orange text-white': 'bg-white text-black'} ` }>
                            <p className="text-4xl font-bold text-center">3 ans</p>
                            <p className="text-center">Standard</p>
                            <p className="text-4xl font-bold text-center py-8">250,00 €</p>
                            <ul>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                                <li>Extension Garantie 5 ans</li>
                            </ul>
                            <div className="pt-8 text-center">
                                <button className="rounded-full border bg-white text-black border-greySoft uppercase font-bold px-4 py-2" onClick={() => setOption(2)}>Choisir</button>
                            </div>
                        </div>
                    </div>
                    <div className='lg:px-52 pt-8'>
                        <input type="checkbox" checked={option === 3 ? true : false} id="aucune_garantie" 
                        onChange={() => setOption(3)} />
                        <label className='ml-2' htmlFor="aucune_garantie">Je ne souhaite aucune extension de garantie pour ce produit</label>
                    </div>
                    <div className="flex justify-center lg:justify-end items-end">
                        <button onClick={() => chooseOptionFromParent()} className='px-12 py-2 text-white rounded-lg bg-green font-bold'>Valider ma séléction</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModalExtensionGarantieComponent