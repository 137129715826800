import logo from './logo.svg';
import './App.css';
import HomePage from './pages/homePage';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import ProductPage from './pages/productPage';
import CategoriePage from './pages/categoriePage';
import { CheckoutPage } from './pages/checkoutPage';
import { PanierPage } from './pages/panierPage';
import MonComptePage from './pages/monComptePage';
import LoginPage from './pages/loginPage';



function App() {
  return (
    <Routes>
        <Route path='/' Component={HomePage} />
        <Route path='/:categoryName/:linkrewrite' Component={ProductPage} />
        <Route path='/:categoryName' Component={CategoriePage} />
        <Route path='/passer-commande' Component={CheckoutPage} />
        <Route path='/panier' Component={PanierPage} />
        <Route path='/mon-compte' Component={MonComptePage} />
        <Route path='/connexion' Component={LoginPage} />
    </Routes>
  );
}

export default App;
