import React from 'react'
import HeaderComponent from '../components/headerComponent'

function CategoriePage() {
  return (
    <>
      <HeaderComponent />
      <div><h1>Page catégorie</h1></div>
    </>
  )
}

export default CategoriePage